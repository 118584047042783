import moment from 'moment';

jQuery( document ).ready(function($) {

  AOS.init();

  // mobile menu
  $('.menu-trigger').click(function(){
      $('.menu').toggleClass('active')
  })
  $('.menu-trigger a').click(function(){
    $('.menu').toggleClass('active')
  })
  

  // Form
  jQuery(document).ready(function ($) {
    $("#saucy-santana-newsletter-form-2022-copy").on("submit", function (e) {
      // Prevent default browser actions on form submission.\
      e.preventDefault();
      // Get all the form user submitted data and get it ready to be sent.\
      var data = $(this).serialize();
      // POST all the data to the SMF submission endpoint URL.\
      $.ajax({
        type: "POST",
        url: "https://subs.sonymusicfans.com/submit",
        dataType: "json",
        data: data,
        xhrFields: {
          withCredentials: false
        },
        success: function (data) {
          $('.form-success').slideToggle()
          $('form').slideToggle()
          $('.form-error').hide()
        },
        error: function (err) {
          $('.form-error').slideToggle()
        }
      });
    });
  });

  // music
  setTimeout(() => {
    const audio = new Audio("./dist/img/material.mp3" )
    $('.music-play').fadeToggle()
    $('.music-play').click(function(e){
      e.preventDefault()
      audio.play()
    })
  }, "2000")

  // slider
  $('.slider').slick({
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      }
    ]
  });

  $('.videos-slider').slick({
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  });

  $('.divider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    mobileFirst: true,
    autoplay: true,
    autoplaySpeed: 0,
    speed:2000,
    infinite: true,
    cssEase:'linear',
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 5,
        }
      }
    ]
  });

  // videos hover
  $(".video-item").hover(function () {
    $(this).children("video")[0].play();
  }, function () {
      var el = $(this).children("video")[0];
      el.pause();
      el.currentTime = 0;
  }); 

  // Tour
	$.ajax( {
		url: 'https://rest.bandsintown.com/artists/Saucy%20Santana/events?app_id=45PRESS_saucy-santana',
		method: 'GET',
		dataType: 'json',
		xhrFields: {
			withCredentials: false
		},
		error: () => {
		},
		success: data => {
			const events = $( '#events' );
			let html = '';
			if ( data.length ) {
				for ( let event of data ) {
					const event_location = typeof event.region !== 'undefined' ? event.venue.city + ', ' + event.venue.region : event.venue.city + ', ' + event.venue.country;
					html += '<div class="event" data-bit-id="' + event.id + '">';
					html += '<div class="event-date">' + moment( event.datetime ).format( 'MMM DD' ) + '</div>';
					html += '<div class="event-venue-location">';
					html += '<div class="event-venue">' + event.venue.name + '</div>';
					html += '<div class="event-location">' + event_location + '</div>';
					html += '</div>';
					html += '<div class="event-links">';
					html += '<a href="' + event.url + '" target="_blank" class="btn">Tickets</a>';
					html += '</div>';
					html += '</div>';
				}
				events.html( html );
			} else {
				events.html( 'No upcoming events.' );
			}
		}
	} );

});